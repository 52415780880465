<template>
<div class="section-wrapper">
  <div class="sub-section-wrapper">
      <breadcrumbs :content="breadcrumbsContents"></breadcrumbs>
    <div class="wrapper-box">
      <div class="title">
          联系方式
        </div>
      <div class="main-box">
        <div class="left">
          <div class="card">
            <div class="ele-i" v-for="(item,index) in content" :key="index">
              <div class="card-ele-inner-wrapper">
                <div class="icon-wrapper">
                  <Icon :type="item.icon" :size="28"/>
                </div>
                <div class="ele-content-wrapper">
                  <p class="i-title">
                    {{item.title}}
                  </p>
                  <p class="i-content">
                    {{item.content}}
                  </p>
                </div>
              </div>
            </div>
          </div>
          
      </div>
      <div class="right">
        <div class="map-wrapper">
          <el-amap
          class="amap-box"
          :zoom="map.zoom"
          :center="map.center"
          :plugin="map.plugins"
          >
          <el-amap-marker :position="map.center" />
          <el-amap-info-window
            :position="map.center"
            :offset="map.offset"
            :is-custom="true"
          >
            <div id="info-window" @click="linkTo(map.url)">
              <p class="info-title">{{map.companyName}}</p>
              <p class="info-address">{{ map.address }}</p>
              
            </div>
          </el-amap-info-window>
          </el-amap>
        </div>
      </div>
      </div>
      <div class="sub-box">

      </div>
      
    </div>
    <div class="wrapper-box">
      <div class="title">
          国内业务布局
        </div>
      <div class="down-main-box">
        <div class="sub-card" v-for="(item,index) in moreContacts" :key="index">
          <p class="head">
            {{item.location}}
          </p>
          <div class="down-c" v-for="(subitem,subindex) in item.contents" :key="subindex" v-show="subitem.value">
            <div class="label-d">{{subitem.label}}：</div>
            <div class="value-d">{{subitem.value}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
 
</template>
<script>
import breadcrumbs from "@/components/Libs/Breadcrumbs.vue"
export default {
  name: "introduce",
  components: {
    breadcrumbs
  },
  props: {
  },
  data() {
    return {
        imgUrl: process.env.BASE_URL + "img/cover.jpg",
        content:[
          {
            icon:'ios-pin',
            title:'总部地址',
            content:"上海市嘉定区安亭镇春锦路253号"
          },
           {
            icon:'ios-people',
            title:'服务热线',
            content:"021-56556312"
          },
           {
            icon:'ios-call',
            title:'投诉电话',
            content:"021-56551276"
          },
           {
            icon:'ios-mail',
            title:'邮箱',
            content:"srcc@chinasrcc.com"
          },

        ],
        map:{
          zoom:13,
          center:[121.21274,31.295149],
          url:"https://surl.amap.com/9UDuKSX4xP",
          plugins: ['AMap.Scale','AMap.ToolBar'],
          offset:[0,-30],
          companyName:"上海轨道交通检测认证（集团）有限公司",
          address:"地址：上海市嘉定区安亭镇春锦路253号",
        },
        moreContacts:[
          // {location:"上海总部",contents:[{label:"业务咨询",value:"姜女士"},{label:"电话",value:"13501676670"},{label:"微信",value:"2229314193"},{label:"邮箱",value:"jxl@obiosh.com"},]},
          // {location:"大连",contents:[{label:"业务咨询",value:"姜女士"},{label:"电话",value:"13501676670"},{label:"微信",value:"2229314193"},{label:"邮箱",value:"jxl@obiosh.com"},]},
          // {location:"成都",contents:[{label:"业务咨询",value:"姜女士"},{label:"电话",value:"13501676670"},{label:"微信",value:"2229314193"},{label:"邮箱",value:"jxl@obiosh.com"},]},
          // {location:"天津",contents:[{label:"业务咨询",value:"姜女士"},{label:"电话",value:"13501676670"},{label:"微信",value:"2229314193"},{label:"邮箱",value:"jxl@obiosh.com"},]},
          // {location:"沈阳",contents:[{label:"业务咨询",value:"姜女士"},{label:"电话",value:"13501676670"},{label:"微信",value:"2229314193"},{label:"邮箱",value:"jxl@obiosh.com"},]},
          // {location:"广州",contents:[{label:"业务咨询",value:"姜女士"},{label:"电话",value:"13501676670"},{label:"微信",value:"2229314193"},{label:"邮箱",value:"jxl@obiosh.com"},]},

        ],
        Tpage: {
            currentPage: 1,
            currentSize: 100
          },
          lang:"中文",
        
    };
  },
  created(){
    this.getData();
  },
  methods: {
    getData() {
      this.spin = true;
      this.$axios
        .post(this.$url + '/flist', {
          askData:'offices',
          page: this.Tpage,
          lang:this.lang,
        })
        .then(
          response => {
            for(let i of response.data.res_record){
              i.contents = JSON.parse(i.content);
              this.moreContacts.push(i);
            }
            this.spin = false;
          }
        );
    },
    linkTo: function (value) {
      window.open(value,"_blank");
    },
  },
  computed:{
      breadcrumbsContents:function(){
          return [{text:'首页',url:'/'},{text:'联系我们',url:'/contact'},];
      },
  },
  mounted() {
  },
};
</script>
<style scoped>

.section-wrapper{
  width:100%;
  
}

.sub-section-wrapper{
  width: 1280px;
  margin:0 auto;
  overflow: hidden;
  position: relative;
  padding-bottom: 80px;
}

.main-box{
    display: -webkit-flex;
    display: flex;
    display: -ms-flexbox;
    justify-content: space-between;
}

.down-main-box{
  display: -webkit-flex;
    display: flex;
    display: -ms-flexbox;
    justify-content: space-between;
    flex-wrap: wrap;
}

.wrapper-box{
  padding: 0 20px 80px;
}

.card{
  box-shadow: 0px 0px 8px 0px rgb(0 0 0 / 15%);
}

.sub-card{
  box-shadow: 0px 0px 6px 0px rgb(0 0 0 / 15%);
  width:48%;
  margin-bottom: 50px;
  padding: 30px 40px;
}

.sub-card .head{
  color:#00508e;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
}

.sub-card .down-c{
  font-size: 16px;
  line-height: 2;
  color:#333;
}

.label-d{
  color:#999;
  font-size: 13px;
  line-height: 2.4;
  width:70px;
  text-align: right;
  display: block;
  float: left;
}

.value-d{
  margin-left:70px;
}

.ele-i{
  padding: 0 20px;
}

.card-ele-inner-wrapper{
  display: -webkit-flex;
    display: flex;
    display: -ms-flexbox;
    justify-content: space-between;
    padding: 15px 0px;
    border-bottom: 1px solid #f4f4f4;
}

.icon-wrapper{
  width:20%;
  text-align: center;
  color:#00508e;
  padding-top:14px;
}

.ele-content-wrapper{
  width:80%;
}

.i-title{
  font-size: 16px;
  font-family: 微软雅黑;
  font-weight: 600;
  color:#999;
  line-height: 2.4;
}

.i-content{
  font-size: 15px;
  color:#333;
}

.left{
    width:33%;
}
.right{
    width:65%;
}

.title{
  font-size: 38px;
  font-weight: 700;
  margin-bottom: 50px;
  text-align: center;
  color:#00508e;
}

.map-wrapper{
  width:100%;
  height:369px;
  border:1px solid #ddd;
}

#info-window{
  background-color: #000;
  opacity: 80%;
  color:#fff;
  padding: 10px 20px;
  border-radius: 3px;
  cursor: pointer;
}
#info-window .info-title{
  font-size: 14px;
  font-weight: 600;
}

#info-window .info-address{
  font-size: 12px;
  line-height: 3;
}

</style>